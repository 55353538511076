/* eslint-disable func-style, no-console */ const localLogger = {
    log () {
        if (Globals.PROD_BUILD) {
            return;
        }
        console.log.apply(console, arguments);
    },
    error () {
        if (Globals.PROD_BUILD) {
            return;
        }
        (console.error || console.log).apply(console, arguments);
    },
    warn () {
        if (Globals.PROD_BUILD) {
            return;
        }
        (console.warn || console.log).apply(console, arguments);
    }
};
export default localLogger;
