import { createContext } from 'preact/compat';
import { defaultAccount } from './models/account';
import { defaultAppSettings } from './models/app-settings';
const noop = ()=>undefined;
export const TranslationsInfoContext = createContext({
    translations: {},
    update: noop
});
export const IdentityContext = createContext({
    state: 'UNAUTHORIZED',
    account: defaultAccount,
    update: noop
});
export const AppSettingsContext = createContext({
    ...defaultAppSettings,
    update: noop
});
export const DeviceInfoContext = createContext(undefined);
export const OrdersBrokerContext = createContext(undefined);
export const PortfolioBrokerContext = createContext(undefined);
export const OrderBookBrokerContext = createContext(undefined);
export const WatchlistBrokerContext = createContext(undefined);
export const UpvoteBrokerContext = createContext(undefined);
export const AutoInvestBrokerContext = createContext(undefined);
export const PublicMessageBrokerContext = createContext(undefined);
export const SecureMessageBrokerContext = createContext(undefined);
export const QuotecastContext = createContext(undefined);
export const BaseAssetContext = createContext({
    symbol: 'EUR',
    name: 'Eur',
    displayFractionDigits: 2,
    tradingFractionDigits: 2,
    tradingStatus: 'ACTIVE',
    supportedOrderTypes: [
        'MARKET',
        'LIMIT'
    ]
});
