import { useEffect } from 'preact/compat';
export default function useDeviceBackButton(callback) {
    useEffect(()=>{
        if (!callback) {
            return;
        }
        // save references to the initial methods before cordova.js wraps them
        const addEventListener = document.addEventListener.bind(document);
        const removeEventListener = document.removeEventListener.bind(document);
        // cordova.js event
        addEventListener('backbutton', callback, false);
        return ()=>removeEventListener('backbutton', callback, false);
    // cordova.js wraps addEventListener/removeEventListener methods on `window` and `document` objects
    // which means we need to re-subscribe on the event again after methods update.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        callback,
        document.addEventListener
    ]);
}
