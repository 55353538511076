import useRouter from '@finst/core/src/scripts/components/router/hooks/use-router';
import useAsync from '@finst/core/src/scripts/hooks/use-async';
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import remoteLogger from '@finst/core/src/scripts/loggers/remote-logger';
import { useEffect, useState } from 'preact/compat';
import { defaultAccount } from '../../../models/account';
import canSaveAccountLocally from '../../../services/identity/can-save-account-locally';
import getEmailVerificationUrlParams from '../../../services/identity/get-email-verification-url-params';
import getIdentity from '../../../services/identity/get-identity';
import getIdentityStateAccount from '../../../services/identity/get-identity-state-account';
import getLocalAccount from '../../../services/identity/get-local-account';
import saveAccountLocally from '../../../services/identity/save-account-locally';
export default function useIdentityContextLoader(secureMessageBroker) {
    const { location } = useRouter();
    const [contextValue, setContextValue] = useState(()=>{
        const emailVerificationUrlParams = getEmailVerificationUrlParams(location.search);
        return {
            ...location.pathname === '/identity/login/email-verification' && emailVerificationUrlParams ? {
                ...emailVerificationUrlParams,
                state: 'WAIT_LOGIN_BY_LINK'
            } : {
                state: 'UNAUTHORIZED'
            },
            account: defaultAccount,
            update: (data)=>setContextValue(({ update })=>({
                        ...data,
                        update
                    }))
        };
    });
    const isLocalAccountSupported = canSaveAccountLocally();
    const { isLoading: isLocalAccountLoading } = useAsync(async ()=>{
        const localAccount = isLocalAccountSupported ? await getLocalAccount() : undefined;
        if (localAccount) {
            setContextValue((contextValue)=>({
                    ...contextValue,
                    account: contextValue.account === defaultAccount ? {
                        ...defaultAccount,
                        ...localAccount
                    } : {
                        ...defaultAccount,
                        ...localAccount,
                        ...contextValue.account
                    }
                }));
        }
    }, [
        isLocalAccountSupported
    ]);
    const { isLoading: isIdentityLoading } = useAsync(async ()=>{
        const stateInfo = await getIdentity();
        const identityStateAccount = getIdentityStateAccount(stateInfo);
        setContextValue((contextValue)=>{
            const { state, account, update } = contextValue;
            // If we confirm email link, we should ignore identity state from `getIdentity()` as it's based on the
            // active session cookie that might be related to a different account.
            if (state === 'WAIT_LOGIN_BY_LINK') {
                return contextValue;
            }
            return {
                ...stateInfo,
                account: identityStateAccount ? {
                    ...account,
                    ...identityStateAccount
                } : account,
                update
            };
        });
    }, []);
    const { account: { id: accountId, email, firstName, lastName }, state } = contextValue;
    useEffect(()=>{
        var _secureMessageBroker;
        return (_secureMessageBroker = secureMessageBroker) === null || _secureMessageBroker === void 0 ? void 0 : _secureMessageBroker.on({
            action: 'verificationPaymentComplete',
            onError: remoteLogger.error,
            onSuccess (stateInfo) {
                const identityStateAccount = getIdentityStateAccount(stateInfo);
                setContextValue(({ account, update })=>({
                        ...stateInfo,
                        account: identityStateAccount ? {
                            ...account,
                            ...identityStateAccount
                        } : account,
                        update
                    }));
            }
        });
    }, [
        secureMessageBroker
    ]);
    useEffect(()=>{
        if (isLocalAccountSupported && email && accountId && state !== 'UNAUTHORIZED' && state !== 'WAIT_LOGIN_BY_LINK' && state !== 'WAIT_PIN_SETUP' && state !== 'TWO_FA_VERIFICATION') {
            // save account data locally in the app when account is logged in and verified
            saveAccountLocally({
                email,
                firstName,
                lastName
            }).catch(localLogger.error);
        }
    }, [
        isLocalAccountSupported,
        state,
        accountId,
        email,
        firstName,
        lastName
    ]);
    return {
        isLoading: isLocalAccountLoading || isIdentityLoading,
        contextValue
    };
}
