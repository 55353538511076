import subscribeOnAppDeepLink from '@finst/core/src/scripts/utils/platform/subscribe-on-app-deep-link';
import { useEffect } from 'preact/compat';
const getRootHostName = (hostname)=>hostname.split('.').slice(-2).join('.');
export default function useAppRootDeepLink(deviceInfo) {
    useEffect(()=>{
        return subscribeOnAppDeepLink(({ url })=>{
            const { hostname: targetHostName, pathname: targetPathname } = new URL(url, window.location.href);
            const { hostname: currentHostName } = window.location;
            const targetRootHostName = getRootHostName(targetHostName);
            // Ignore redirects from the root domain (marketing website). See an app banner on the website in Safari.
            // Ignore redirects from other origins (malicious Intent).
            // Ignore redirects from app install page (encoded in QR code), see `src/views/app-store.ejs`.
            if (targetRootHostName !== targetHostName && targetRootHostName === getRootHostName(currentHostName) && !targetPathname.startsWith('/app-store')) {
                window.location.replace(url);
            }
        });
    // the presence/absence of `deviceInfo` indicates if webview API layer is loaded or not
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        deviceInfo === undefined
    ]);
}
