import getRelativeDifference from '@finst/core/src/scripts/utils/number/get-relative-difference';
export default function calculateAssetPosition(position, assetPrice, assetRelativeDifference) {
    const { breakEvenPrice } = position;
    // it's acceptable for us to lose a precision in this calculation
    const size = Number(position.size);
    const price = assetPrice;
    const value = price * size;
    const absoluteDailyPl = position.dailyBasePl + value;
    const absoluteTotalPl = position.basePl + value;
    const absolutePositionPl = position.positionBasePl + value;
    const absoluteUnrealisedPl = (price - breakEvenPrice) * size;
    return {
        ...position,
        price,
        value,
        absoluteDailyPl,
        relativeDailyPl: assetRelativeDifference !== null && assetRelativeDifference !== void 0 ? assetRelativeDifference : position.relativeDailyPl,
        absoluteTotalPl,
        absolutePositionPl,
        absoluteUnrealisedPl,
        relativeUnrealisedPl: absoluteUnrealisedPl ? getRelativeDifference(price, breakEvenPrice) : 0,
        absoluteRealisedPl: absoluteTotalPl - absoluteUnrealisedPl,
        absoluteRealisedPositionPl: absolutePositionPl - absoluteUnrealisedPl
    };
}
