import { useCallback, useEffect, useState } from 'preact/compat';
const getCurrentHash = ()=>window.location.hash.slice(1);
export default function useLocationHash() {
    const [hash, setHash] = useState(getCurrentHash);
    const update = useCallback((hash)=>window.location.hash = hash && `#${hash}`, []);
    useEffect(()=>{
        const onHashChange = ()=>setHash(getCurrentHash());
        window.addEventListener('hashchange', onHashChange, false);
        return ()=>window.removeEventListener('hashchange', onHashChange, false);
    }, []);
    return {
        hash,
        update
    };
}
