import { useEffect, useState } from 'preact/compat';
import useRouter from './use-router';
function defaultMapToUrlParams(params) {
    const allowedUrlParamTypes = [
        'string',
        'number',
        'boolean'
    ];
    return Object.fromEntries(Object.entries(params).filter((entry)=>{
        const value = entry[1];
        return value == null || allowedUrlParamTypes.includes(typeof value);
    }));
}
export default function useLocationSearchParamsState(mapFromUrlParams, mapToUrlParams = defaultMapToUrlParams) {
    const { location, navigate } = useRouter();
    const { search, pathname } = location;
    const [params, setParams] = useState(()=>mapFromUrlParams(new URLSearchParams(search)));
    useEffect(()=>{
        const searchParams = new URLSearchParams(search);
        Object.entries(mapToUrlParams(params)).forEach(([name, value])=>{
            if (value || value === 0 || value === false) {
                searchParams.set(name, String(value));
            } else {
                searchParams.delete(name);
            }
        });
        const newSearch = searchParams.toString();
        if (newSearch !== search.replace(/^\?/, '')) {
            navigate(pathname + (newSearch && `?${newSearch}`), {
                replace: true
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        params
    ]);
    useEffect(()=>{
        setParams(mapFromUrlParams(new URLSearchParams(search)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        search
    ]);
    return [
        params,
        setParams
    ];
}
