import { _ as _define_property } from "@swc/helpers/_/_define_property";
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import isNonEmptyArray from '@finst/core/src/scripts/utils/collection/is-non-empty-array';
import unsubscribeAll from '@finst/core/src/scripts/utils/subscription/unsubscribe-all';
import { emptyTotalPortfolio } from '../../models/portfolio';
import calculateAssetPositions from './calculate-asset-positions';
import calculateTotalPortfolio from './calculate-total-portfolio';
const quotecastFields = [
    'lastPrice',
    'relativeDifference',
    'referencePrice'
];
class PortfolioBroker {
    recalculateAndEmitTotalPortfolio() {
        var _baseAssetPosition, _baseAssetPosition1;
        const { assetPositionsTotals, bundlePositionsTotals } = this;
        const baseAssetPosition = this.assetPositions.find((position)=>position.assetSymbol === this.baseAssetSymbol);
        const totalPortfolio = calculateTotalPortfolio({
            balance: Number(((_baseAssetPosition = baseAssetPosition) === null || _baseAssetPosition === void 0 ? void 0 : _baseAssetPosition.availableSize) || 0),
            assets: assetPositionsTotals.value + bundlePositionsTotals.value - Number(((_baseAssetPosition1 = baseAssetPosition) === null || _baseAssetPosition1 === void 0 ? void 0 : _baseAssetPosition1.value) || 0),
            absoluteTotalPl: assetPositionsTotals.absoluteTotalPl + bundlePositionsTotals.absoluteTotalPl,
            absoluteDailyPl: assetPositionsTotals.absoluteDailyPl + bundlePositionsTotals.absoluteDailyPl
        });
        this.totalPortfolio = totalPortfolio;
        this.totalPortfolioSubscriptions.forEach((callback)=>callback(totalPortfolio));
    }
    processAssetPositionsUpdate(updatedAssetPositions, _options) {
        var _this_unsubscribePositionsFromQuotecast, _this;
        const { baseAssetSymbol, assetPositions } = this;
        this.assetPositions = [];
        const quotecastKeys = [];
        const storePosition = (position)=>{
            this.assetPositions.push(position);
            if (position.assetSymbol !== baseAssetSymbol) {
                quotecastKeys.push(`${position.assetSymbol}~${baseAssetSymbol}`);
            }
        };
        assetPositions.forEach((storedPosition)=>{
            const updatedPosition = updatedAssetPositions.get(storedPosition.id);
            if (updatedPosition) {
                // Delete handled position
                updatedAssetPositions.delete(updatedPosition.id);
                storePosition(updatedPosition);
            } else {
                storePosition(storedPosition);
            }
        });
        // All unhandled positions are new
        updatedAssetPositions.forEach((position)=>storePosition(position));
        this.areAssetPositionsCalculated = false;
        (_this_unsubscribePositionsFromQuotecast = (_this = this).unsubscribePositionsFromQuotecast) === null || _this_unsubscribePositionsFromQuotecast === void 0 ? void 0 : _this_unsubscribePositionsFromQuotecast.call(_this);
        if (isNonEmptyArray(quotecastKeys)) {
            this.unsubscribePositionsFromQuotecast = this.quotecast.onUpdate(quotecastKeys, quotecastFields, this.recalculateAssetPositionsAfterPricesUpdate);
        } else {
            this.unsubscribePositionsFromQuotecast = undefined;
            // trigger an empty update for empty positions list
            this.recalculateAssetPositionsAfterPricesUpdate({});
        }
    }
    processBundlePositionsUpdate(updatedBunldePositions, _options) {
        var _this_unsubscribeBundlePositionsFromQuotecast, _this;
        const { baseAssetSymbol, bundlePositions } = this;
        this.bundlePositions = [];
        const quotecastKeys = [];
        const storeBundlePosition = (bundlePosition)=>{
            const normalizedBundlePosition = Array.isArray(bundlePosition.positions) ? bundlePosition : {
                ...bundlePosition,
                positions: Object.values(bundlePosition.positions)
            };
            this.bundlePositions.push(normalizedBundlePosition);
            quotecastKeys.push(`${bundlePosition.bundleSymbol}~${baseAssetSymbol}`);
            normalizedBundlePosition.positions.forEach((position)=>{
                if (position.assetSymbol !== baseAssetSymbol) {
                    quotecastKeys.push(`${position.assetSymbol}~${baseAssetSymbol}`);
                }
            });
        };
        bundlePositions.forEach((storedBundlePosition)=>{
            const updatedPosition = updatedBunldePositions.get(storedBundlePosition.id);
            if (updatedPosition) {
                // Delete handled position
                updatedBunldePositions.delete(updatedPosition.id);
                storeBundlePosition({
                    ...updatedPosition,
                    positions: Object.values(updatedPosition.positions)
                });
            } else {
                storeBundlePosition(storedBundlePosition);
            }
        });
        // All unhandled positions are new
        updatedBunldePositions.forEach(storeBundlePosition);
        this.areBundlePositionsCalculated = false;
        (_this_unsubscribeBundlePositionsFromQuotecast = (_this = this).unsubscribeBundlePositionsFromQuotecast) === null || _this_unsubscribeBundlePositionsFromQuotecast === void 0 ? void 0 : _this_unsubscribeBundlePositionsFromQuotecast.call(_this);
        if (isNonEmptyArray(quotecastKeys)) {
            this.unsubscribeBundlePositionsFromQuotecast = this.quotecast.onUpdate(quotecastKeys, quotecastFields, this.recalculateBundlePositionsAfterPricesUpdate);
        } else {
            this.unsubscribeBundlePositionsFromQuotecast = undefined;
            // trigger an empty update for empty positions list
            this.recalculateBundlePositionsAfterPricesUpdate({});
        }
    }
    onTotalPortfolio(callback) {
        this.totalPortfolioSubscriptions.add(callback);
        if (this.areAssetPositionsCalculated) {
            callback(this.totalPortfolio);
        }
        return ()=>{
            this.totalPortfolioSubscriptions.delete(callback);
        };
    }
    onAssetPositions(callback) {
        this.assetPositionsSubscriptions.add(callback);
        if (this.areAssetPositionsCalculated) {
            const nonBaseAssetPositions = this.assetPositions.filter((position)=>position.assetSymbol !== this.baseAssetSymbol);
            callback(nonBaseAssetPositions);
        }
        return ()=>{
            this.assetPositionsSubscriptions.delete(callback);
        };
    }
    onBundlePositions(callback) {
        this.bundlePositionsSubscriptions.add(callback);
        if (this.areBundlePositionsCalculated) {
            callback(this.bundlePositions);
        }
        return ()=>{
            this.bundlePositionsSubscriptions.delete(callback);
        };
    }
    close() {
        var _this_unsubscribePositionsFromQuotecast, _this, _this_unsubscribeBundlePositionsFromQuotecast, _this1;
        (_this_unsubscribePositionsFromQuotecast = (_this = this).unsubscribePositionsFromQuotecast) === null || _this_unsubscribePositionsFromQuotecast === void 0 ? void 0 : _this_unsubscribePositionsFromQuotecast.call(_this);
        (_this_unsubscribeBundlePositionsFromQuotecast = (_this1 = this).unsubscribeBundlePositionsFromQuotecast) === null || _this_unsubscribeBundlePositionsFromQuotecast === void 0 ? void 0 : _this_unsubscribeBundlePositionsFromQuotecast.call(_this1);
        this.unsubscribePositionsFromQuotecast = undefined;
        this.unsubscribeBundlePositionsFromQuotecast = undefined;
        unsubscribeAll(this.messabeBrokerUnsubscribeList);
        this.assetPositionsSubscriptions.clear();
        this.bundlePositionsSubscriptions.clear();
    }
    constructor(props){
        _define_property(this, "quotecast", void 0);
        _define_property(this, "messageBroker", void 0);
        _define_property(this, "baseAssetSymbol", void 0);
        _define_property(this, "isSnapshotReceived", false);
        _define_property(this, "lastUpdatedAt", void 0);
        _define_property(this, "isInitialUpdateReceived", false);
        _define_property(this, "areAssetPositionsCalculated", false);
        _define_property(this, "areBundlePositionsCalculated", false);
        _define_property(this, "assetPositions", []);
        _define_property(this, "bundlePositions", []);
        _define_property(this, "assetPositionsTotals", {
            value: 0,
            absoluteDailyPl: 0,
            absoluteTotalPl: 0
        });
        _define_property(this, "bundlePositionsTotals", {
            value: 0,
            absoluteDailyPl: 0,
            absoluteTotalPl: 0
        });
        _define_property(this, "totalPortfolio", {
            ...emptyTotalPortfolio
        });
        _define_property(this, "totalPortfolioSubscriptions", new Set());
        _define_property(this, "assetPositionsSubscriptions", new Set());
        _define_property(this, "bundlePositionsSubscriptions", new Set());
        _define_property(this, "unsubscribePositionsFromQuotecast", void 0);
        _define_property(this, "unsubscribeBundlePositionsFromQuotecast", void 0);
        _define_property(this, "messabeBrokerUnsubscribeList", void 0);
        _define_property(this, "requestPortfolioSnapshot", ()=>{
            this.isSnapshotReceived = false;
            this.messageBroker.send({
                action: 'portfolioRequest',
                data: {},
                onError: localLogger.error
            });
        });
        _define_property(this, "recalculateAssetPositionsAfterPricesUpdate", (update)=>{
            this.areAssetPositionsCalculated = true;
            const { positions, hasChangedReferencePrice, value, absoluteDailyPl, absoluteTotalPl } = calculateAssetPositions(this.baseAssetSymbol, this.assetPositions, update);
            const nonBaseAssetPositions = positions.filter((position)=>position.assetSymbol !== this.baseAssetSymbol);
            this.assetPositions = positions;
            this.assetPositionsTotals = {
                value,
                absoluteDailyPl,
                absoluteTotalPl
            };
            this.recalculateAndEmitTotalPortfolio();
            this.assetPositionsSubscriptions.forEach((callback)=>callback(nonBaseAssetPositions));
            // If the snapshot is pending, we do not need to request it again
            if (hasChangedReferencePrice && this.isSnapshotReceived) {
                this.requestPortfolioSnapshot();
            }
        });
        _define_property(this, "recalculateBundlePositionsAfterPricesUpdate", (update)=>{
            const { baseAssetSymbol } = this;
            const bundlePositionsTotals = {
                value: 0,
                absoluteDailyPl: 0,
                absoluteTotalPl: 0
            };
            let shouldRefreshPortfolio = false;
            this.areBundlePositionsCalculated = true;
            this.bundlePositions = this.bundlePositions.map((bundlePosition)=>{
                const { positions, hasChangedReferencePrice, ...totalValues } = calculateAssetPositions(baseAssetSymbol, bundlePosition.positions, update);
                shouldRefreshPortfolio = shouldRefreshPortfolio || hasChangedReferencePrice;
                bundlePositionsTotals.value += totalValues.value;
                bundlePositionsTotals.absoluteDailyPl += totalValues.absoluteDailyPl;
                bundlePositionsTotals.absoluteTotalPl += totalValues.absoluteTotalPl;
                return {
                    ...bundlePosition,
                    ...totalValues,
                    positions
                };
            });
            this.bundlePositionsTotals = bundlePositionsTotals;
            this.recalculateAndEmitTotalPortfolio();
            this.bundlePositionsSubscriptions.forEach((callback)=>callback(this.bundlePositions));
            // If the snapshot is pending, we do not need to request it again
            if (shouldRefreshPortfolio && this.isSnapshotReceived) {
                this.requestPortfolioSnapshot();
            }
        });
        _define_property(this, "onPortfolioSnapshot", (update)=>{
            this.isSnapshotReceived = true;
            this.onPortfolioUpdate(update);
        });
        _define_property(this, "onPortfolioUpdate", (update)=>{
            // Ignore the update if it's older than the last received one
            if (this.lastUpdatedAt && new Date(this.lastUpdatedAt).getTime() > new Date(update.updatedAt).getTime()) {
                return;
            }
            const updateOptions = {
                isInitialUpdate: !this.isInitialUpdateReceived
            };
            this.lastUpdatedAt = update.updatedAt;
            this.isInitialUpdateReceived = true;
            this.processAssetPositionsUpdate(new Map(Object.entries(update.positions)), updateOptions);
            this.processBundlePositionsUpdate(new Map(Object.entries(update.bundlePositions)), updateOptions);
        });
        const { messageBroker } = props;
        this.quotecast = props.quotecast;
        this.messageBroker = props.messageBroker;
        this.baseAssetSymbol = props.baseAssetSymbol;
        this.messabeBrokerUnsubscribeList = [
            messageBroker.on({
                action: 'portfolioData',
                onSuccess: this.onPortfolioSnapshot,
                onError: localLogger.error
            }),
            messageBroker.on({
                action: 'portfolioUpdate',
                onSuccess: this.onPortfolioUpdate,
                onError: localLogger.error
            }),
            // Request a snapshot again when a new connection established (e.g. after reconnect)
            messageBroker.onConnect(this.requestPortfolioSnapshot)
        ];
    }
}
export { PortfolioBroker as default };
